import Routers from '@app/contracts/routers.json'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Stack } from 'react-bootstrap'
import { useSearchParam } from 'react-use'
import link from '../images/popup-link-icon.svg'
import { fToken } from '../util/formatNumber'
import { useRelativeTokenPrice } from '../web3/price-data'
import { getUsdCoin, sameTokens, useLocalCoin, getExplorerTxLink } from '../web3/token-utils'
import { getDefaultRouterAddress } from '../web3/utils'
import { addChain, networks } from '../web3/web3'
import NetworkSetter from "./network/networksetter"
import PaymentMethod from './payment-method/payment-method'

import { useCallback, useContext } from 'react'
import NetworkContext from '../context/network-context'
import { useAccount } from '../state/account'
import { useApprove } from '../web3/account'
import { usePaymentHooks } from '../web3/payments/payment'
import './accept.scss'
import Checkmark from './checkmark-status/checkmark-status'

function AcceptPayment() {
  const { connected, connect, isCorrectNetwork, executeTransaction, pendingTransaction } = useContext(NetworkContext);

  const receiver = useSearchParam('receiver');
  const chain = useSearchParam('chain');
  const coin = useSearchParam('coin');
  const amount = useSearchParam('amount');

  const [paymentCoin, setPaymentCoin] = useState();
  const [done, setDone] = useState(false);
  const [txLink, setTxLink] = useState();

  const [showApprove, setShowApproved] = useState(false);

  const token = useLocalCoin(chain, coin);
  const [baseUsdPrice] = useRelativeTokenPrice(getUsdCoin(chain), token, getDefaultRouterAddress(chain), chain);
  const { makePayment } = usePaymentHooks();

  const account = useAccount();
  const { approve, isApproved } = useApprove(paymentCoin?.address, account, Routers[chain]?.SIMPLE, chain);

  useEffect(() => {
    setPaymentCoin(token);
  }, [token])

  useEffect(() => {
    if (sameTokens(paymentCoin, token)) {
      setShowApproved(false);
      return;
    }

    setShowApproved(!isApproved);
  }, [isApproved, setShowApproved, paymentCoin])

  const pay = useCallback(async () => {
    const tx = async () => await makePayment(token, paymentCoin, +amount, receiver);

    try {
      const { receipt } = await executeTransaction({
        message: 'Sending Payment',
        tx,
        throwErr: true
      });

      setTxLink(getExplorerTxLink(receipt.txid, chain));
      setDone(true);
    } catch (err) {
      console.log(err);
    }
  }, [paymentCoin, token, executeTransaction, amount, receiver, makePayment])

  return (
    <Container>
      <NetworkSetter chainId={chain} ></NetworkSetter>
      <Row>
        <Col md={6} className="d-flex flex-column mx-auto my-5">
          <Stack gap={3}>
            <Stack direction="horizontal">
              <div className="payment-description">Pay</div>
              <div className="payment-detail ms-auto">{amount} {token?.symbol}</div>
            </Stack>
            <Stack direction="horizontal">
              <div className="payment-description">To</div>
              <div className="payment-detail ms-auto">
                {receiver}
                <img
                  src={link}
                  alt="Open in block explorer"
                  className="image-button ms-2"
                />
              </div>
            </Stack>

            <hr />
            <Stack direction="horizontal">
              <div className="payment-description">Total</div>
              <Stack>
                <div className="total-amount ms-auto">{amount} {token?.symbol}</div>
                <div className="text-muted ms-auto">~{fToken(baseUsdPrice * amount)} USD</div>
              </Stack>
            </Stack>

            <div>
              <div className="payment-description">Pay With</div>
              <PaymentMethod className=" my-3"
                defaultCoin={token}
                amount={amount}
                onSelect={setPaymentCoin}
              />
            </div>

            <div className="text-muted">Service fee: 0</div>

            {connected && isCorrectNetwork && !done &&
              <>{
                !showApprove ?
                  <Button variant="primary" type="botton"
                    onClick={pay}
                    disabled={pendingTransaction}
                    className="w-100 mt-3 mb-4">
                    Pay
                  </Button> :
                  <Button variant="primary"
                    type="botton"
                    onClick={() => approve()}
                    disabled={pendingTransaction}
                    className="w-100 mt-3 mb-4">
                    Approve
                  </Button>
              }
              </>
            }

            {connected && !isCorrectNetwork &&
              <Button
                onClick={() => addChain(chain)}
                variant="primary" type="botton"
                className="w-100 mt-3 mb-4">
                Switch to {networks[chain].shortName} network
              </Button>}
            {!connected && <Button variant="primary" type="button" onClick={connect} className="w-100 mt-3 mb-4">
              Connect Wallet
            </Button>}

            {done && <Checkmark status={'success'} />}
            {done && <div>
              <p>{txLink}</p>
              <a href={txLink} target="_blank" rel="noopener noreferrer">View</a>
            </div>
            }
            <p className="disclaimer">Web3Pay does not collect private information related to your crypto wallet. Make sure that you are on the official Web3Pay.org website.</p>

          </Stack>
        </Col>
      </Row>
    </Container>
  )
}

export default AcceptPayment
