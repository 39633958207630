
import Routers from '@app/contracts/routers.json';
import UniswapV2 from '../contracts/abi/RouterV2.json';
import ASwapper from './abis/ASwapper.json';

import { ethers } from 'ethers';
import { RUNTIME_ENV } from "gatsby-env-variables";
import { useEffect, useState } from 'react';
import { fetchTokenBalance } from '../account';
import { ContractFactoryHof } from '../contracts/contract.factory';
import { getNativeCoin } from '../token-utils';

export const getRouter = async (path, swapper) => {
    if (!path || !swapper) {
        return;
    }

    const inToken = path[0];
    const outToken = path[path.length - 1];

    if (!inToken || !outToken) {
        return;
    }

    if (path[0].chainId !== path[path.length - 1].chainId) {
        return;
    }

    const isCore = inToken.core && outToken.core;
    if (isCore) {
        console.log("Using core router");
        return HpayRouter(swapper);
    }

    console.log("Using fallback router", swapper);
    return UniswapRouter(swapper);
}

export const swapAndTransfer = (router, callback) => async (path, amount, minAmount, account, to, trackOrder) => {

    if (!router) {
        return;
    }

    const method = router.getRouterMethod(path, amount, minAmount, to);

    const { result, order } = await method(account);
    order.account = ethers.utils.getAddress(account);

    callback({ result, order, trackOrder });

    return result;
};

export const useSwap = (swapper, base, quote) => {
    const router = useRouter(swapper, base, quote)

    const swapFn = swapAndTransfer(router, ({ order, trackOrder }) => {
        console.log("here")
    });

    return { swap: swapFn, router };
}

export const useRouter = (swapper, base, quote) => {
    const [router, setRouter] = useState();

    useEffect(() => {
        getRouter([base, quote], swapper).then((_router) => {
            setRouter(_router);
        });
    }, [swapper, base, quote]);

    return router;
}


const getRouterMethod = ({ nativeCoin, fromEthToTokens, fromTokenToEth, fromTokenToTokens }) => (path, amount, minAmount, to) => {
    if (path[0].address === nativeCoin.address) {
        const execute = fromEthToTokens(path, amount, minAmount, to);
        return async (account) => {
            const tx = await execute(account)
            const { inputToken, outputToken, amountOut, amountIn } = await calculatedAmounts(path, account, tx);
            const _amountIn = amountIn - (tx.gasUsed * tx.effectiveGasPrice) / 1e18;
            const order = getOrder(inputToken, tx.transactionHash, outputToken, amountOut, _amountIn)
            return { result: tx, order }
        }
    }

    if (path[path.length - 1].address === nativeCoin.address) {
        const execute = fromTokenToEth(path, amount, minAmount, to);

        return async (account) => {
            const tx = await execute(account)
            const { inputToken, outputToken, amountOut, amountIn } = await calculatedAmounts(path, account, tx);
            const _amountOut = amountOut + (tx.gasUsed * tx.effectiveGasPrice) / 1e18;
            const order = getOrder(inputToken, tx.transactionHash, outputToken, _amountOut, amountIn)
            return { result: tx, order }
        }

    }

    const execute = fromTokenToTokens(path, amount, minAmount, to);
    return async (account) => {
        const tx = await execute(account)
        const { inputToken, outputToken, amountOut, amountIn } = await calculatedAmounts(path, account, tx);
        const order = getOrder(inputToken, tx.transactionHash, outputToken, amountOut, amountIn);
        return { result: tx, order }
    }
}


const UniswapRouter = async (swapper) => {
    const FEE = 0;
    const router = await ContractFactoryHof(window.web3).create(UniswapV2.abi, swapper.router)
    const nativeCoin = getNativeCoin(swapper.chainId);

    const fromEthToTokens = (path, amount, minAmount) => async (account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: amount
        };
        const configuration = router.methods
            .swapExactETHForTokensSupportingFeeOnTransferTokens(
                minAmount,
                path.map(item => item.address),
                account,
                Date.now() + 5000
            )

        await configuration.estimateGas(args);
        return configuration.send(args);
    };

    const fromTokenToEth = (path, amount, minAmount) => async (account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        const configuration = router.methods
            .swapExactTokensForETHSupportingFeeOnTransferTokens(
                amount,
                minAmount,
                path.map(item => item.address),
                account,
                Date.now() + 5000
            )
        await configuration.estimateGas(args);
        return configuration.send(args);

    };

    const fromTokenToTokens = (path, amount, minAmount) => async (account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        const configuration = router.methods
            .swapExactTokensForTokensSupportingFeeOnTransferTokens(
                amount,
                minAmount,
                path.map(item => item.address),
                account,
                Date.now() + 5000
            )
        await configuration.estimateGas(args);
        return configuration.send(args);
    };

    return {
        getRouterMethod: getRouterMethod({
            nativeCoin, fromEthToTokens, fromTokenToEth, fromTokenToTokens,
            swapperAddress: swapper.router,
        }),
        FEE, ADDRESS: swapper.router
    }
}

const BaseRouter = async (swapper, abi, address) => {
    const router = await ContractFactoryHof(window.web3).create(abi, address)
    const nativeCoin = getNativeCoin(swapper.chainId);

    const fromEthToTokens = (path, amount, minAmount, to) => async (account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: amount
        };

        const configuration = router.methods.swapFromNative(
            swapper.router,
            path.map(item => item.address),
            minAmount,
            to
        )

        await configuration.estimateGas(args);
        return configuration.send(args);
    };

    const fromTokenToEth = (path, amount, minAmount, to) => async (account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        const configuration = router.methods.swapToNative(
            swapper.router,
            path.map(item => item.address),
            amount,
            minAmount,
            to
        );

        await configuration.estimateGas(args);
        return configuration.send(args);
    };

    const fromTokenToTokens = (path, amount, minAmount, to) => async (account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        console.log(amount / 1e18, minAmount / 1e18);
        const configuration = router.methods.swap(
            swapper.router,
            path.map(item => item.address),
            amount,
            minAmount,
            to
        );
        await configuration.estimateGas(args);
        return configuration.send(args);
    };

    return {
        getRouterMethod: getRouterMethod({
            nativeCoin, fromEthToTokens, fromTokenToEth, fromTokenToTokens,
            swapperAddress: address,
        }),
        ADDRESS: address
    }
}

const HpayRouter = async (swapper) => {
    if (RUNTIME_ENV === 'mainnet' && Routers[swapper.chainId].SIMPLE !== '0x76da5668E5210094002ABc6af6f0102d08658908') {
        throw new Error("Invalid router");
    }
    return BaseRouter(swapper, ASwapper.abi, Routers[swapper.chainId].SIMPLE)
}


async function calculatedAmounts(path, account, tx) {
    const inputToken = path[0];
    const outputToken = path[path.length - 1];

    const { outBefore, ouAfter, inBefore, inAfter } = await balanceInBetweenBlocks(inputToken, account, tx, outputToken);
    const amountOut = ouAfter - outBefore;
    let amountIn = inBefore - inAfter;
    return { inputToken, outputToken, amountOut, amountIn };
}

async function balanceInBetweenBlocks(inputToken, account, tx, outputToken) {
    const inBefore = await fetchTokenBalance(inputToken.address, account, inputToken.chainId, tx.blockNumber - 1);
    const inAfter = await fetchTokenBalance(inputToken.address, account, inputToken.chainId, tx.blockNumber);

    const outBefore = await fetchTokenBalance(outputToken.address, account, outputToken.chainId, tx.blockNumber - 1);
    const ouAfter = await fetchTokenBalance(outputToken.address, account, outputToken.chainId, tx.blockNumber);
    return { outBefore, ouAfter, inBefore, inAfter };
}

function getOrder(inputToken, txid, outputToken, amountOut, _amountIn) {
    return {
        chain: inputToken.chainId,
        txid,
        from: inputToken,
        to: outputToken,
        price: amountOut / _amountIn,
        amountOut,
        amountIn: _amountIn,
        date: Date.now()
    };
}
