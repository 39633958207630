import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Modal, Stack } from 'react-bootstrap'
import NetworkContext from '../../context/network-context'
import caret from '../../images/Triangle.svg'
import CoinSelectList from '../coin-select-list/coin-select-list'
import './payment-method.scss'

import { useDebounce } from 'react-use'
import { fToken } from '../../util/formatNumber'
import { useRelativeTokenPrice } from '../../web3/price-data'
import { fetchPairs, sameTokens } from '../../web3/token-utils'
import { getDefaultRouterAddress } from '../../web3/utils'
import { useWalletBalance } from '../../web3/account'

const Connect = ({ connect }) => {
  return (
    <div role="button"
      tabIndex={0}
      className="connect-btn not-connected"
      onKeyUp={connect}
      onClick={connect}>
      Connect wallet to begin
      <img
        className="caret"
        style={{
          transform: 'rotate(270deg)',
        }}
        src={caret}
        alt="more"
      />
    </div>
  )
}

const Account = (account, value, handleClick, selectedCoin, error) => (
  <div>
    <div
      role="button"
      tabIndex={0}
      onKeyUp={handleClick}
      className="connect-btn connected"
      onClick={handleClick}
    >
      <img className="coin-icon" src={selectedCoin?.image} alt="icon" />
      <Stack className='my-auto'>
        <div className='balance'>{fToken(value)} {selectedCoin?.symbol}</div>
        <div className="address">{account.slice(0, 6) + '...' + account.slice(-10)}</div>
      </Stack>

      <img
        className="caret"

        style={{
          transform: 'rotate(270deg)',
        }}
        src={caret}
        alt="more"
      />
    </div>
    {error && <small style={{ color: 'red' }} className="m-auto" children={error} />}
  </div>
)

function PaymentMethod({ defaultCoin, amount, onSelect }) {
  const {
    account,
    connected,
    unlocked,
    network,
    connect,
    pendingTransaction
  } = useContext(NetworkContext)

  const [show, setShow] = useState(false);
  const [coins, setCoins] = useState(false);
  const [selected, setSelected] = useState();
  const [search, setSearch] = useState();
  const [relativePrice] = useRelativeTokenPrice(selected, defaultCoin, getDefaultRouterAddress(selected?.chainId), selected?.chainId);
  const [sendAmount, setSendAmount] = useState(0);
  const [error, setError] = useState();

  const { balance } = useWalletBalance(selected?.address, account, network);

  const handleSelect = (coin) => {
    if (pendingTransaction) {
      return;
    }

    setSelected(coin);
    setShow(false);

    if (onSelect) {
      onSelect(coin)
    }
  };

  const handleShow = (status) => () => {
    setShow(status)
  }

  const loadCoins = useCallback(
    (search) => fetchPairs(network, search).then(data => {
      setCoins(data);
      // if (ethers.utils.isAddress(search)) {
      //   setFetching(true);
      //   fetchUserToken(search).then(_data => {
      //     setCoins([...data, ..._data]);
      //     setFetching(false);
      //   });
      // }
    }), [network]);

  useDebounce(() => {
    loadCoins(search)
  }, 100, [search])

  useEffect(() => {
    fetchPairs(network, null, []).then(setCoins)
  }, [setCoins, network, selected])

  useEffect(() => {
    setSelected(defaultCoin);
  }, [defaultCoin])

  useEffect(() => {
    if (!defaultCoin || !selected) {
      return;
    }
    if (sameTokens(selected, defaultCoin)) {
      setSendAmount(amount);
      return;
    }

    const tokenFees = (defaultCoin.buyFee || 0) + (selected.sellFee || 0);
    setSendAmount(amount * relativePrice * (1.02 + tokenFees / 100));
  }, [relativePrice, defaultCoin, selected, amount, setSendAmount])

  useEffect(() => {
    if (balance < sendAmount) {
      setError("Insufficient balance");
      return;
    }

    setError(null);
  }, [balance, setError, sendAmount])


  let child

  if (!unlocked || !connected || !account) {
    child = Connect({ connect })
  } else {
    child = Account(account, sendAmount,
      handleShow(true),
      selected, error
    )
  }
  return <>
    {child}
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleShow(false)} >
      <Modal.Body style={{ height: 415 }}>
        <CoinSelectList
          coins={coins}
          onSelect={handleSelect}
          onSearch={setSearch}
        />
      </Modal.Body>
    </Modal>
  </>
}

export default PaymentMethod;
