import React, { useContext, useEffect, useState } from 'react'
import { Badge, Col, FormControl, InputGroup } from 'react-bootstrap'
import NetworkContext from '../../context/network-context'
import networkIcon from '../../images/chain-icon'
import { fToken } from '../../util/formatNumber'
import { fetchTokenBalance } from '../../web3/account'
import { networks } from '../../web3/web3'


const CoinItemSelect = ({ image, symbol, onSelect, onRemove, address, chainId, unverified }) => {
    const [balance, setBalance] = useState();
    const { account } = useContext(NetworkContext)

    useEffect(() => {
        fetchTokenBalance(address, account, chainId).then(setBalance);
    }, [address, account, chainId])

    return <div
        role="button" onKeyPress={onSelect} tabIndex={0} onClick={() => onSelect()}
        className="modal-row-item coin-item-dropdown-select align-items-center">
        <div>
            <h5>{symbol}
                <img style={{ width: '15px', height: '15px', marginLeft: '0.75rem' }}
                    src={networkIcon[networks[+chainId].shortName]} alt="icon" />
                {unverified && <Badge pill className="ms-2 badge-primary border-0" >
                    <span className="text-primary-gradient ">
                        Unverified
                    </span>
                </Badge>}

                {balance && <span>{fToken(balance)}</span>}
            </h5>
            <small className='generic-label--small-thin'>
                {networks[chainId].shortName} : {address.slice(0, 5)}...{address.slice(30)}
            </small>
        </div>
        <div>
            {unverified && <Badge pill
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onRemove();
                }}
                className="me-2 badge-primary clickable" >
                <span className="text-primary-gradient ">
                    Remove
                </span>
            </Badge>}
            <img
                src={image}
                alt={symbol}
                width={45}
                height={45}
            />
        </div>
    </div>
};

const CoinSelectList = ({ coins, onSelect, onRemove, showSearch, onSearch, fetching }) => {

    return <>
        {showSearch && <Col md={12} className="mb-md-5 mx-auto">
            <InputGroup className='form-hpay-input-group'>
                <FormControl placeholder="Search here..."
                    onChange={(e) => { onSearch(e.target.value) }}
                    className='form-hpay' />
            </InputGroup>
        </Col>}
        <Col md={12} style={{ overflowX: 'scroll', maxHeight: 320 }}>
            <div>
                {
                    coins && coins.map((coin, index) => <CoinItemSelect className="mb-1"
                        {...coin}
                        onRemove={() => onRemove(coin)}
                        onSelect={() => onSelect(coin)}
                        key={index} />
                    )
                }
            </div>
            {(!coins || coins.length === 0) && !fetching && "Token not found or it has low liquidity... Trying searching by contract address..."}
            {fetching && "Searching..."}
        </Col>
    </>
}

export default CoinSelectList;