import React, { useState, useEffect } from "react";
import AcceptPayment from "../components/AcceptPayment";
import Layout from "../components/layout";
import Seo from "../components/seo";

const useIsClient = () => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? "client" : "server";

  useEffect(() => {
    setClient(true);
  }, []);

  return { isClient, key };
};

const Pay = () => {
  const { isClient } = useIsClient();

  return (
    <Layout pageInfo={{ pageName: "pay" }}>
      <Seo title="Pay" keywords={[`crypto`, `payments`, `defi`]} />
      {isClient && <AcceptPayment />}
    </Layout>
  );
};

export default Pay;
