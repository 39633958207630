import { parseEther, parseUnits } from 'ethers/lib/utils';
import { useCallback, useMemo } from 'react';
import { useAccount } from '../../state/account';
import ERC20 from "../contracts/abi/ERC20.json";
import { ContractFactoryHof } from '../contracts/contract.factory';
import { fetchBestPrice } from '../price-data';
import { getRouter, swapAndTransfer } from '../router/router-utils';
import { getNativeCoin, getUsdCoin, sameTokens } from '../token-utils';

const makeEthPayment = (provider, account) => async (amount, to) => {
    const args = { to, value: amount, from: account };
    await provider.eth.estimateGas(args);
    return provider.eth.sendTransaction(args);
}

const makeTokenPayment = (provider, account) => async (amount, tokenAddress, to) => {
    const args = { value: 0, from: account };
    const contract = await ContractFactoryHof(provider).create(ERC20.abi, tokenAddress);
    console.log(to, amount);
    await contract.methods.transfer(to, amount).estimateGas(args);
    return contract.methods.transfer(to, amount).send(args);
}

const isSlippageError = err => {
    try {
        let _error = err.message;
        const start = _error.indexOf("{");
        const end = _error.lastIndexOf("}");

        if (start && end) {
            _error = JSON.parse(_error.substring(start, end + 1));
        }

        if (_error.message.includes("INSUFFICIENT_OUTPUT_AMOUNT")) {
            return true;
        }
    } catch (error) {
        console.log(error);
    }
}

const swapAndPay = (provider, account) => async (requestToken, sendToken, amount, to) => {
    const { router: liquidityPoolProvider, slippage, price, path } = await fetchBestPrice(amount, requestToken, sendToken);

    const usdCoin = getUsdCoin(requestToken.chainId);
    let usdPrice;
    if (sameTokens(usdCoin, requestToken)) {
        usdPrice = 1;
    } else {
        usdPrice = await fetchBestPrice(amount, requestToken, usdCoin).then(data => data.price);
    }

    const router = await getRouter([sendToken, requestToken], liquidityPoolProvider);
    const swapFn = swapAndTransfer(router, () => { });

    const usdValue = amount * usdPrice;
    let baseRate = 0.75;

    if (usdValue >= 1000) {
        baseRate = 0.5;
    }

    if (usdValue >= 5000) {
        baseRate = 0.25;
    }

    if (usdValue >= 10000) {
        baseRate = 0.2;
    }

    const getAmounts = (adjustment = 0) => {
        let _amount = amount * price;
        const fee = slippage + baseRate + adjustment + (requestToken.buyFee || 0) + (sendToken.sell || 0);

        _amount = _amount + (_amount * +(fee.toFixed(4)) / 100);
        _amount = parseUnits(_amount.toFixed(sendToken.decimals), sendToken.decimals);
        const minOut = parseUnits(amount.toFixed(requestToken.decimals), requestToken.decimals);

        console.log(_amount / 1e18, minOut / 1e18);
        return { _amount, minOut };
    }

    const tryTrade = async adjustment => {
        let { _amount, minOut } = getAmounts(adjustment);
        return swapFn(path.reverse(), _amount, minOut, account, to, false);
    }
    return tryTrade(0)
        .catch(err => {
            if (isSlippageError(err)) {
                console.warn("slippage-error");
                return tryTrade(0.5)
            }
            throw err;
        }).catch(err => {
            if (isSlippageError(err)) {
                console.warn("slippage-error");
                return tryTrade(2.5);
            }
            throw err;
        });
}


const usePaymentMethods = (provider, account) => {
    return useMemo(() => ({
        makeEthPayment: makeEthPayment(provider, account),
        makeTokenPayment: makeTokenPayment(provider, account),
        swapAndPay: swapAndPay(provider, account)
    }), [provider, account]);
}

export const usePaymentHooks = (provider = window.web3) => {
    const account = useAccount();
    const { swapAndPay, makeEthPayment, makeTokenPayment } = usePaymentMethods(provider, account);

    const makePayment = useCallback((requestToken, sendToken, amount, to) => {

        if (!sameTokens(requestToken, sendToken)) {
            return swapAndPay(requestToken, sendToken, amount, to);
        }
        const nativeCoin = getNativeCoin(sendToken.chainId);
        if (requestToken.address === nativeCoin.address) {
            return makeEthPayment(parseEther(amount.toFixed(18)), to);
        }
        return makeTokenPayment(parseUnits(amount.toFixed(requestToken?.decimals), requestToken?.decimals), requestToken.address, to);
    }, [swapAndPay, makeEthPayment, makeTokenPayment]);

    return { makePayment };
}